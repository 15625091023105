html {
    scroll-behavior: smooth;
}
html, body, #root {
    height: 100%;
}
/*html, body, div#root, div.App {*/
/*    height: 100%;*/
/*}*/
h1, h2, h3, h4, h5, h6 {
    margin-top: 1rem;
}
/*.App {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/
main {
    max-width: 900px;
    margin: auto;
    /*flex: 1 0 auto;*/
}

#navbar, p.header-text {
    max-width: 1200px;
    margin: auto;
}
#overview {
    background-color: black;
    color: white;
}
.no-bottom-margin {
    margin-bottom: 0;
}

.turn-order {
    background-color: lightsteelblue;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.flex-container {
    display: flex;
    justify-content: center;
}

.page-header{
    text-align: center;
}
.spinner-container {
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;


    /*position: absolute;*/
    /*top: 0;*/
    /*bottom: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    /*margin: auto;*/
}

/* Super Small devices (landscape phones, 576px and up)*/
@media (max-width: 575.98px) {
    body, .turn-order {
        padding: 0.5rem;
    }
    header {
        padding: 0 0.5rem;
    }
    #navbar {
        padding: 0.5rem;
    }
}
/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
    body, .turn-order {
        padding: 1rem 2rem;
    }
    header {
        padding: 0 1rem;
    }
}

